import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const EditMeasureDataContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735635590/KPI%20Documentations/Measure%20data/Edit%20measure%20data/q2uxjlumq3wqjj2pq31d.mov'} />
}

const EditMeasureData = () => {
    return <PageContainer content={<EditMeasureDataContent />} />
}

export default EditMeasureData