import React from 'react'
import { VideoContainer } from '../../../../componets/VideoContainder'
import PageContainer from '../../../../componets/pageContainer'

const GeneralDashboardContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735825923/KPI%20Documentations/Dashboards/General%20dashboard/ns3akj7vrd16xtq571wt.mov'} />
}
const GeneralDashboard = () => {
    return <PageContainer content={<GeneralDashboardContent />} />
}

export default GeneralDashboard