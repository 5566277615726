import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const NewKPIUserContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735822298/KPI%20Documentations/Users/add%20new%20user/y4wpkj9ffdr27juzmfuw.mov'} />
}

const NewKPIUser = () => {
    return <PageContainer content={<NewKPIUserContent />} />
}


export default NewKPIUser