import React from 'react'
import { VideoContainer } from '../../../../componets/VideoContainder'
import PageContainer from '../../../../componets/pageContainer'

const DeleteUserContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735824178/KPI%20Documentations/Users/delete%20user/s5gvluuu5ctaefv2os3p.mov'} />
}
const DeleteUser = () => {
    return <PageContainer content={<DeleteUserContent />} />
}

export default DeleteUser