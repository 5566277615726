export const qControlFeatures = [
  {
    featureName: "Authentication",
    description:
      "This authentication is mostly about gaining access to the tool it includes:",
    links: [
      { name: "Login", link: "/qc/auth/login" },
      { name: "Logout", link: "/qc/auth/logout" },
      { name: "Reset Password", link: "/qc/auth/reset-password" },
    ],
  },
  {
    featureName: "Incidents",
    description:
      "This is the main area where you will be able to view, create, and modify incidents:",
    links: [
      { name: "List incidents", link: "/qc/incident/list/" },
      { name: "New incident", link: "/qc/incident/new" },
      { name: "Incident details", link: "/qc/incident/details" },
      { name: "Incident Drafts", link: "/qc/incident/drafts" },
      { name: "Modifications", link: "/qc/incident/modifications" },
      { name: "Search filters", link: "/qc/incident/search-filters" },
    ],
  },
  {
    featureName: "Facilities and departments",
    description:
      "This is where you will be able to view, create, and modify facilities and departments:",
    links: [{ name: "Manage", link: "/qc/facilities/manage" }],
  },
  {
    featureName: "Manage users",
    description:
      "This feature allows you to manage users, including creating, editing, and deleting:",
    links: [{ name: "Manage users", link: "/qc/admin/manage-users" }],
  },
  {
    featureName: "Support",
    links: [{ name: "Documentation", link: "/qc/support" }],
  },
  {
    featureName: "Complaints",
    links: [
      { name: "Submit complaint", link: "/qc/complaints/submit" },
      // { name: 'List complaints', link: '/qc/complaints/list' },
      // { name: 'View complaint', link: '/qc/complaints/view' },
      // { name: 'Modify complaint', link: '/qc/complaints/modify' },
      // { name: 'Delete complaint', link: '/qc/complaints/delete' },
    ],
  },
];
