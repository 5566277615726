import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const PositionsContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735635649/KPI%20Documentations/Positions/kzob7wyucq2a49habgaa.mov'} />
}

const Positions = () => {
    return <PageContainer content={<PositionsContent />} />
}
export default Positions