import React from 'react'
import { VideoContainer } from '../../../../componets/VideoContainder'
import PageContainer from '../../../../componets/pageContainer'

const QControlLogoutCOntent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640151/Quality%20control%20documentation/Login%20and%20Logout/atgn58dqqbaif9ylktw9.mov'} />
}

const QControlLogout = () => {
    return <PageContainer content={<QControlLogoutCOntent />} />
}

export default QControlLogout