import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const IncidentDetailsContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640248/Quality%20control%20documentation/Incidents/View%20Incident%20Details/cb4skn5veixcej1j13jb.mov'} />
}
const IncidentDetails = () => {
    return <PageContainer content={<IncidentDetailsContent />} />
}

export default IncidentDetails