import React from 'react'
import { Link } from 'react-router-dom'
import PageContainer from '../../../../componets/pageContainer'


function AboutPageContent() {
    return (
        <div className='page-content'>
            <div className="features-title">
                <div className="icon">I</div>
                <h1>Introduction</h1>
            </div>
            <div>
                <h3>What is KPI</h3>
                <p>KPI is the platform that will help cohesive to manage all the measure and measure data for their hospitals.</p>

            </div>
            <h3>Key features</h3>
            <div className="features-cards cards">
                {
                    features && features.map((feature) => (
                        <a href={feature.link} className="card">
                            <div className="card-title">
                                <div className="icon">{feature.icon}</div>
                                <h3>{feature.title}</h3>
                            </div>
                            <p>{feature.description}</p>
                        </a>
                    ))
                }
            </div>

            <Link>Click here to login</Link>
        </div>
    )
}


const KpiAboutPage = () => {
    return (
        <PageContainer content={<AboutPageContent />} />
    )
}
export default KpiAboutPage

const features = [
    {
        icon: 'Q',
        title: 'Quality control',
        description: 'A feature that handles login',
        link: '/qc/'

    },

    {
        icon: 'K',
        title: 'KPI Tool',
        description: 'A feature that handles how to logout from system',
        link: '/kpi/'
    },
]