import React from "react";
import PageContainer from "../../../../componets/pageContainer";
import { VideoContainer } from "../../../../componets/VideoContainder";

const ForgotPasswordContent = () => {
  return (
    <VideoContainer
      url={
        "https://res.cloudinary.com/dqvkbnolw/video/upload/v1736154680/Quality%20control%20documentation/Reset%20Password/qzmqgj9qhqpijwqzocn6.mov"
      }
    />
  );
};

function ForgotPassword() {
  return <PageContainer content={<ForgotPasswordContent />} />;
}

export default ForgotPassword;
