import React from 'react'
import { VideoContainer } from '../../../../componets/VideoContainder'
import PageContainer from '../../../../componets/pageContainer'

const LogoutContent = () => {
  return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735821606/KPI%20Documentations/Authentication/Logout/ub73wa5bu77xa3fbm8du.mov'} />
}
const KPILogout = () => {
  return <PageContainer content={<LogoutContent />} />
}

export default KPILogout