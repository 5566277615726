import React from 'react'
import { VideoContainer } from '../../../componets/VideoContainder'
import PageContainer from '../../../componets/pageContainer'

const SupportContent = () => {
    return (
        <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640222/Quality%20control%20documentation/Support/fthi8hviecyip0piypvj.mov'} />
    )
}

const Support = () => {
    return <PageContainer content={<SupportContent />} />
}

export default Support