import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'


const EditTargetContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735635500/KPI%20Documentations/Target/Edit%20target/opqwytiyyloeu90llmnl.mov'} />
}
const EditTarget = () => {
    return <PageContainer content={<EditTargetContent />} />
}

export default EditTarget