import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const NewMeasureDataContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735635656/KPI%20Documentations/Measure%20data/Add%20measure%20data/cjrt3inbamqj9njgsbea.mov'} />
}

const NewMeasureData = () => {
    return <PageContainer content={<NewMeasureDataContent />} />
}

export default NewMeasureData