import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const SearchAndFilterContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640214/Quality%20control%20documentation/Incidents/Search%20and%20Filter/q8lxcocvzkqmrvse6pss.mov'} />
}

const SearchAndFilter = () => {
    return <PageContainer content={<SearchAndFilterContent />} />
}

export default SearchAndFilter