import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const QControlLoginContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735643843/Quality%20control%20documentation/Login%20and%20Logout/kvsoj6wgxyfrnj4qtadu.mov'} />
}

function QControlLogin() {
    return <PageContainer content={<QControlLoginContent />} />
}

export default QControlLogin