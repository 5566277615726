import React from 'react'
import { VideoContainer } from '../../../../componets/VideoContainder'
import PageContainer from '../../../../componets/pageContainer'

const SubmitComplaintContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1736154950/Quality%20control%20documentation/Complaints/npttbwfqthesp2ocvtoq.mov'} />
}
const SubmitComplaint = () => {
    return <PageContainer content={<SubmitComplaintContent />} />
}

export default SubmitComplaint