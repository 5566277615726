import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const DraftsContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640275/Quality%20control%20documentation/Incidents/Drafts/pl5otmzjqylvui6kotvs.mov'} />
}

const Drafts = () => {
    return <PageContainer content={<DraftsContent />} />
}

export default Drafts