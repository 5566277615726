import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'


const IncidentModificationContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640223/Quality%20control%20documentation/Incidents/Modify%20Incident/zrdbcaeyt5szeykrx9lf.mov'} />
}
const IncidentModification = () => {
    return <PageContainer content={<IncidentModificationContent />} />
}

export default IncidentModification