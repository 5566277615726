export const kpiFeatures = [

    {
        featureName: 'Authentication',
        links: [
            { name: 'Login', link: '/kpi/auth/login' },
            { name: 'Logout', link: '/kpi/auth/logout' },
            { name: "Reset Password", link: '/kpi/auth/reset-password' }
        ]
    },
    {
        featureName: 'Dashboard',
        links: [
            { name: 'General dashboard', link: '/kpi/dashboard/general' },
            { name: 'Single Facility', link: '/kpi/dashboard/single' },
            { name: 'Multiple Facilities', link: '/kpi/dashboard/multiple' },
        ]
    },
    {
        featureName: 'Measures',
        links: [
            { name: 'New measure', link: '/kpi/measures/new' },
            { name: 'Edit measure', link: '/kpi/measures/edit' },
            { name: 'Delete measure', link: '/kpi/measures/delete' },
        ]
    },
    {
        featureName: 'Measure data',
        links: [
            { name: 'New measure data', link: '/kpi/measure-data/new' },
            { name: 'Edit measure data', link: '/kpi/measure-data/edit' },
            { name: 'Delete measure data', link: '/kpi/measure-data/delete' },
        ]
    },
    {
        featureName: 'Positions',
        links: [
            { name: 'View positions', link: '/kpi/positions' },
        ]
    },
    {
        featureName: 'Users',
        links: [
            { name: 'New user', link: '/kpi/users/new' },
            { name: 'Edit user', link: '/kpi/users/edit' },
            { name: 'Delete user', link: '/kpi/users/delete' },
        ]
    },
    {
        featureName: 'Targets',
        links: [
            { name: 'New target', link: '/kpi/targets/new' },
            { name: 'Edit target', link: '/kpi/targets/edit' },
            { name: 'Delete target', link: '/kpi/targets/delete' },
        ]
    }
]