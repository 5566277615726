import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const ManageUsersContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640256/Quality%20control%20documentation/Manage%20Users/seyr8ipzbksep1fxpnct.mov'} />
}

const ManageUsers = () => {
    return <PageContainer content={<ManageUsersContent />} />
}

export default ManageUsers