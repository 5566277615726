import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const ResetPasswordContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735821580/KPI%20Documentations/Authentication/reset%20password/b0et4rgjtxy2gu4ulcq7.mov'} />
}
const ResetPassword = () => {
    return <PageContainer content={<ResetPasswordContent />} />
}

export default ResetPassword