import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

function LoginContent() {
  return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735821641/KPI%20Documentations/Authentication/Login/ufec6p0avitqtgspgalj.mov'} />
}


const KPILogin = () => {
  return (
    <PageContainer content={<LoginContent />} />
  )
}
export default KPILogin
