import React from 'react'
import { VideoContainer } from '../../../../componets/VideoContainder'
import PageContainer from '../../../../componets/pageContainer'

const SingleFacilityContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735825828/KPI%20Documentations/Dashboards/Single%20hospital%20dashboard/jsy0gfanfaub1kggje0a.mov'} />
}
const SingleFacility = () => {
    return <PageContainer content={<SingleFacilityContent />} />
}

export default SingleFacility