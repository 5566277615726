import React, { useEffect } from 'react'
import PageContainer from '../../../componets/pageContainer'
import { kpiFeatures } from '../../../constants/kpi'

const KPIIntroContent = () => {
    useEffect(() => {
        localStorage.setItem('activeProject', 'KPI')
    }, [])
    return (
        <div className='page-content'>
            <div className="features-title">
                <div className="icon">I</div>
                <h1>Important features</h1>
            </div>
            <div>
                <h3>What's in it</h3>
                <p>Here, you're going to find the core features of the software.</p>
            </div>

            <div className="features-cards cards">
                {
                    kpiFeatures && kpiFeatures.map((feature, index) => (
                        <div className="card">
                            <div className="card-title">
                                <div className="icon">{feature.icon}</div>
                                <h3>{feature.featureName}</h3>
                            </div>
                            <p>{feature.description}</p>
                            <div className="features">

                                {
                                    feature && feature.links.map((link, index) => (
                                        <a className='feature' href={link.link} key={index}>{link.name}</a>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const KPIIntro = () => {
    return <PageContainer content={<KPIIntroContent />} />
}

export default KPIIntro