import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const MultipleFacilitiesContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735825894/KPI%20Documentations/Dashboards/All%20hospital%20dashboard/y8ys42apdrk3j4uwmjup.mov'} />
}
const MultipleFacilities = () => {
    return <PageContainer content={<MultipleFacilitiesContent />} />
}

export default MultipleFacilities