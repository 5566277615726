import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const NewMeasureContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735635622/KPI%20Documentations/Measures/Add%20new%20measure/jff4ykdbysv1m7qbofze.mov'} />
}

const NewMeasure = () => {
    return <PageContainer content={<NewMeasureContent />} />
}

export default NewMeasure