import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const NewIncidentContent = () => {
    return (
        <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640199/Quality%20control%20documentation/Incidents/Adding%20New%20Incident/ybjdpntdfoxk0xjpgpch.mov'} />
    )
}

const NewIncident = () => {
    return <PageContainer content={<NewIncidentContent />} />
}

export default NewIncident