import React from 'react'
import { VideoContainer } from '../../../../componets/VideoContainder'
import PageContainer from '../../../../componets/pageContainer'

const DeleteTargetContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735635541/KPI%20Documentations/Target/Delete%20target/voaa4qmbkdddttt0x3pl.mov'} />
}
const DeleteTarget = () => {
    return <PageContainer content={<DeleteTargetContent />} />
}

export default DeleteTarget