import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "./assets/css/main/main.css";
// import AboutPage from './projects/AboutPage';
import NotFound from "./projects/errors/notFound";
import Login from "./projects/kpi/pages/authentication/Login";
import AboutPage from "./projects/kpi/pages/AboutPage/AboutPage";
import GettingStarted from "./projects/kpi/pages/introduction/GettingStarted";
import QualityControlIntro from "./projects/q-control/pages/intro";
import Logout from "./projects/kpi/pages/authentication/Logout";
import Support from "./projects/kpi/pages/Support";
import Drafts from "./projects/q-control/pages/incidents/Drafts";
import IncidentDetails from "./projects/q-control/pages/incidents/IncidentDetails";
import IncidentList from "./projects/q-control/pages/incidents/IncidentList";
import IncidentModification from "./projects/q-control/pages/incidents/IncidentModification";
import NewIncident from "./projects/q-control/pages/incidents/NewIncident";
import SearchAndFilter from "./projects/q-control/pages/incidents/SearchAndFilter";
import ManageUsers from "./projects/q-control/pages/admin/ManageUser";
import ManageFacilities from "./projects/q-control/pages/facilities/ManageFacilities";
import KPIIntro from "./projects/kpi/pages/Intro";
import KPILogin from "./projects/kpi/pages/authentication/Login";
import KPILogout from "./projects/kpi/pages/authentication/Logout";
import NewMeasure from "./projects/kpi/pages/Measures/NewMeasure";
import NewMeasureData from "./projects/kpi/pages/measureData/NewMeasureData";
import EditMeasureData from "./projects/kpi/pages/measureData/EditMeasureData";
import DeleteMeasureData from "./projects/kpi/pages/measureData/DeleteMeasureData";
import Positions from "./projects/kpi/pages/positions/Positions";
import NewKPIUser from "./projects/kpi/pages/users/NewUser";
import NewTarget from "./projects/kpi/pages/targets/NewTarget";
import DeleteTarget from "./projects/kpi/pages/targets/DeleteTarget";
import EditTarget from "./projects/kpi/pages/targets/EditTarget";
import QControlLogout from "./projects/q-control/pages/auth/Logout";
import QControlLogin from "./projects/q-control/pages/auth/Login";
import ResetPassword from "./projects/kpi/pages/authentication/ResetPassword";
import MultipleFacilities from "./projects/kpi/pages/dashboard/MultipleFacilities";
import GeneralDashboard from "./projects/kpi/pages/dashboard/GeneralDashboard";
import SingleFacility from "./projects/kpi/pages/dashboard/SingleFacility";
import DeleteUser from "./projects/kpi/pages/users/DeleteUser";
import SubmitComplaint from "./projects/q-control/pages/compaints/SubmitComplaint";
import ForgotPassword from "./projects/q-control/pages/auth/ForgotPassword";

function App() {
  return (
    <Router>
      <Routes>
        {/* common urls */}
        <Route path="/" element={<AboutPage />} />
        <Route path="*" element={<NotFound />} />

        {/* q-control links */}
        <Route path="/qc/" element={<QualityControlIntro />} />

        {/* Auth */}
        <Route path="/qc/auth/login" element={<QControlLogin />} />
        <Route path="/qc/auth/logout" element={<QControlLogout />} />
        <Route path="/qc/auth/reset-password" element={<ForgotPassword />} />

        {/* Support */}
        <Route path="/qc/support" element={<Support />} />

        {/* Drafts */}
        <Route path="/qc/incident/drafts" element={<Drafts />} />

        {/* Incident */}
        <Route path="/qc/incident/details" element={<IncidentDetails />} />
        <Route path="/qc/incident/list/" element={<IncidentList />} />
        <Route
          path="/qc/incident/modifications"
          element={<IncidentModification />}
        />
        <Route path="/qc/incident/new" element={<NewIncident />} />
        <Route
          path="/qc/incident/search-filters"
          element={<SearchAndFilter />}
        />

        {/* Admin */}
        <Route path="/qc/admin/manage-users" element={<ManageUsers />} />

        {/* Facility */}
        <Route path="/qc/facilities/manage" element={<ManageFacilities />} />

        {/* complaints */}
        {/* <Route path='/complaints' component={Complaints} /> */}
        <Route path="/qc/complaints/submit" element={<SubmitComplaint />} />

        {/* kpi links */}
        <Route path="/kpi" element={<KPIIntro />} />
        <Route path="/introduction" element={<GettingStarted />} />

        {/* auth */}
        <Route path="/kpi/auth/login" element={<KPILogin />} />
        <Route path="/kpi/auth/logout" element={<KPILogout />} />
        <Route path="/kpi/auth/reset-password" element={<ResetPassword />} />

        {/* dashboard */}
        <Route
          path="/kpi/dashboard/multiple"
          element={<MultipleFacilities />}
        />
        <Route path="/kpi/dashboard/general" element={<GeneralDashboard />} />
        <Route path="/kpi/dashboard/single" element={<SingleFacility />} />

        {/* measures */}
        <Route path="/kpi/measures/new" element={<NewMeasure />} />
        <Route path="/kpi/measures/edit" element={<NewMeasure />} />
        <Route path="/kpi/measures/delete" element={<DeleteMeasureData />} />

        {/* measure data */}
        <Route path="/kpi/measure-data/new" element={<NewMeasureData />} />
        <Route path="/kpi/measure-data/edit" element={<EditMeasureData />} />
        <Route
          path="/kpi/measure-data/delete"
          element={<DeleteMeasureData />}
        />

        {/* positions */}
        <Route path="/kpi/positions" element={<Positions />} />

        {/* users */}
        <Route path="/kpi/users/new" element={<NewKPIUser />} />
        <Route path="/kpi/users/edit" element={<NewKPIUser />} />
        <Route path="/kpi/users/delete" element={<DeleteUser />} />

        {/* targets */}
        <Route path="/kpi/targets/new" element={<NewTarget />} />
        <Route path="/kpi/targets/edit" element={<EditTarget />} />
        <Route path="/kpi/targets/delete" element={<DeleteTarget />} />
      </Routes>
    </Router>
  );
}

export default App;
