import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const IncidentListContent = () => {
    return (
        <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640192/Quality%20control%20documentation/Incidents/Incident%20List/zunxnnf7effwlbhsjo7w.mov'} />
    )
}
const IncidentList = () => {
    return <PageContainer content={<IncidentListContent />} />
}

export default IncidentList