import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const DeleteMeasureDataContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735635601/KPI%20Documentations/Measure%20data/Delete%20measure%20data/rdqgp3dl1pqinylkvpx8.mov'} />
}

const DeleteMeasureData = () => {
    return <PageContainer content={<DeleteMeasureDataContent />} />
}

export default DeleteMeasureData