import React from 'react'
import PageContainer from '../../../../componets/pageContainer'
import { VideoContainer } from '../../../../componets/VideoContainder'

const NewTargetContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735635602/KPI%20Documentations/Target/Add%20target/bvo2xcf9awvho8floyx5.mov'} />
}
const NewTarget = () => {
    return <PageContainer content={<NewTargetContent />} />
}

export default NewTarget