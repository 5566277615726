import React from 'react'
import { VideoContainer } from '../../../../componets/VideoContainder'
import PageContainer from '../../../../componets/pageContainer'


const ManageFacilitiesContent = () => {
    return <VideoContainer url={'https://res.cloudinary.com/dqvkbnolw/video/upload/v1735640275/Quality%20control%20documentation/Facility%20and%20Department/blearoe0xneobmm8slqn.mov'} />
}

const ManageFacilities = () => {
    return <PageContainer content={<ManageFacilitiesContent />} />
}

export default ManageFacilities