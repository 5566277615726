export const VideoContainer = ({ url }) => {
    return (
        <video
            style={
                { width: '100%' }
            }
            controls
            autoPlay>
            <source src={url} controls />
        </video>
    )
}